import React, { useState, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { graphql, withPrefix } from 'gatsby'
import styled from 'styled-components'

import Page, { Section } from '../../shared/page-commons'
import SectionHeader from '../../shared/section-header'
import { absoluteSize } from '../../shared/common-styles'
import {
  Grid,
  Card,
  CardActionCall,
  CardTextGroup,
  MoreCard,
} from '../../shared/card-grid'
import { ACTBanner } from '../../shared/banner'
import MDBody from './_sciencecoffee.md'
import Tag from '../../shared/tag'
import { dateFormatter } from '../../shared/formatters'

const Ratio = styled.div`
  padding-bottom: 100%;
`

const Image = styled.img`
  ${absoluteSize};
  display: block;
  object-fit: cover;
`

const Label = styled(Tag)`
  position: absolute;
  top: -10px;
  left: 20px;
  max-width: calc(100% - 40px);
`

const Name = styled.h3`
  font-size: 1.2rem;
  color: var(--heading);
  margin-bottom: 30px;
  flex-grow: 1;
`

const Author = styled.h3`
  font-size: 0.9rem;
  color: var(--heading);
  margin-bottom: 1px;
`
const Institution = styled.h3`
  font-size: 0.9rem;
  color: var(--heading);
  margin-bottom: 10px;
`

const CoffeeCard = React.memo((props) => {
  const { coffee, ...restProps } = props

  return (
    <Card {...restProps}>
      <figure css="position: relative;">
        <Ratio />
        <Image src={withPrefix(coffee.image_src)} alt={coffee.title} />
      </figure>
      <CardTextGroup>
        {coffee.date && !isNaN(new Date(coffee.date)) &&(
          coffee.time
          ?<Label>{dateFormatter.format(new Date(coffee.date))}{' '}{coffee.time}</Label>
          :<Label>{dateFormatter.format(new Date(coffee.date))}</Label>
        )}
        <Author>{coffee.author}</Author>
        <Institution>{coffee.institution}</Institution>
        <Name>{coffee.title}</Name>
        <CardActionCall>About</CardActionCall>
      </CardTextGroup>
    </Card>
  )
})

export default function ScienceCoffeePage(props) {
  const [showingAll, setShowingAll] = useState(false)

  const coffees = useMemo(() => {
    if (showingAll) return props.data.coffees.nodes
    return props.data.coffees.nodes.slice(0, 9)
  }, [props.data.coffees.nodes, showingAll])

  return (
    <Page bgColor="surface">
      <Helmet>
        <meta
          name="description"
          content="The Science Coffe is an informal event where invited speakers present their innovative view on a topic by the ACT researchers."
        />
        <meta
          property="og:description"
          content="The Science Coffe is an informal event where invited speakers present their innovative view on a topic by the ACT researchers."
        />
        <meta
          name="twitter:description"
          content="The Science Coffe is an informal event where invited speakers present their innovative view on a topic by the ACT researchers."
        />
      </Helmet>
      <ACTBanner src="/images/core/coffee_banner.png">collaborate</ACTBanner>
      <Section textColumn>
        <SectionHeader>Science Coffee</SectionHeader>
        <MDBody />
      </Section>
      <Section bgColor="background">
        <Grid>
          {coffees.map(({ frontmatter, fields }) => {
            return (
              <CoffeeCard
                key={frontmatter.title}
                coffee={frontmatter}
                document={frontmatter.document_url}
                href={frontmatter.external_url}
                to={frontmatter.internal_url || fields.slug}
              />
            )
          })}
          {!showingAll && (
            <MoreCard onClick={() => setShowingAll(true)}>
              Show all Science Coffees
            </MoreCard>
          )}
        </Grid>
      </Section>
    </Page>
  )
}

export const query = graphql`
  query ScienceCoffeePage {
    coffees: allMdx(
      filter: { fileAbsolutePath: { glob: "**/src/collections/coffee/**" } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          image_src
          title
          author
          institution
          date
          time
          document_url
          external_url
          internal_url
        }
        fields {
          slug
        }
      }
    }
  }
`
